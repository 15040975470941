import enStrings from "locales/en.json";
import frStrings from "locales/fr.json";

import usFlag from "assets/images/us-flag.png";
import frFlag from "assets/images/fr-flag.png";

export const KEY_LANGUAGE = "lang";
export const LOCALES = {
    EN: "EN",
    FR: "FR"
};

export const LOCALE_STRINGS = {
    [LOCALES.EN]: enStrings,
    [LOCALES.FR]: frStrings
};


export const LOCALE_OPTIONS = [
    { value: LOCALES.EN, label: "language_english", imgSrc: usFlag, imgAlt: "US flag" },
    { value: LOCALES.FR, label: "language_french", imgSrc: frFlag, imgAlt: "French flag" }
];
