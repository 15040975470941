import { Footer } from "components/Footer";
import { SolutionsSection } from "./components/SolutionsSection";
import { HeaderWithNav } from "components/HeaderWithNav";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import { HeroSection } from "components/HeroSection";
import { GetStartedSection } from "components/GetStartedSection";
import { ProductsSection } from "./components/ProductsSection";
import { QuestionsSection } from "pages/Products/components/QuestionsSection";
import { UnlockingSection } from "./components/UnlockingSection";
import { useScrollToElement } from "hooks/useScrollToElement";


export const Home = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    language={language}
                    titleKey={'hero_homeTitle'}
                    descriptionKey={'hero_homeDescription'}
                    showVideo
                    showStarted
                />
                <ProductsSection
                    language={language}
                />
                <UnlockingSection
                    language={language}
                />
                <SolutionsSection
                    language={language}
                />
                <QuestionsSection
                    language={language}
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    );
};
