import { joinClassNames } from "helpers/joinClassNames"
import { parseLines } from "helpers/parseLines"

export const KeyPrincipleCard = ({
    img,
    title,
    content,
    className = '',
    imgClassName = '',
    skipSplit = false,
    id
}) => {
    const lines = skipSplit ? content : parseLines(content);

    return (
        <div
            className={joinClassNames(
                "key-card",
                className
            )}
        >
            <img
                src={img}
                alt=""
                className={joinClassNames(
                    "key-image",
                    imgClassName
                )}
            />
            <p className="subtitle">
                {title}
            </p>
            <p className="text-small">
                {lines}
                <div id={id} />
            </p>
        </div>
    )
}