import { LOCALE_STRINGS } from "constants/locales"
import screenshot from "assets/images/Screenshot-compressed.png";
import { joinClassNames } from "helpers/joinClassNames";
import { GetStartedButtonWithModal } from "pages/Home/components/GetStartedButtonWithModal";

const LINK_TO_VIDEO = "https://vantage-360-landing-content.s3.af-south-1.amazonaws.com/Main+homepage+video.mp4";

export const HeroSection = ({
    language = 'EN',
    titleKey,
    descriptionKey,
    imageSrc = '',
    showVideo = false,
    className = '',
    showStarted = false,
}) => {
    return (
        <section className="home-page__heroSection">
            {showVideo ? (
                <video className="home-page__heroSection__video" poster={screenshot} autoPlay muted loop playsInline>
                    <source src={LINK_TO_VIDEO} type="video/mp4"/>
                </video>
            ) : (
                <img
                    className="home-page__heroSection__video"
                    src={imageSrc ?? screenshot}
                    alt=""
                />
            )}
            <div className="home-page__heroSection__video-overlay"></div>
            <div className={joinClassNames(
                "home-page__heroSection__info",
                showStarted ? '' : 'hero-padding',
                className
            )}>
                <h1 className="home-page__heroSection__title">
                    {LOCALE_STRINGS[language][titleKey]}
                </h1>
                <p className="home-page__heroSection__description">
                    {LOCALE_STRINGS[language][descriptionKey]}
                </p>
                {showStarted && (
                    <GetStartedButtonWithModal className="text-black" />
                )}
            </div>
        </section>
    )
}