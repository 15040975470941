import { LOCALE_STRINGS } from "constants/locales";
import { EMAIL_MAX_LENGTH, EmailRegExp, LENGTH_ERROR, MESSAGE_MAX_LENGTH, NOT_ONLY_SPECIAL_CHARACTERS, PHONE_FIELD_MIN_LENGTH, TEXT_FIELD_MAX_LENGTH, TEXT_FIELD_MIN_LENGTH } from "constants/validations";
import * as Yup from 'yup';

export const contactValidationSchema = (language) => {
    const lengthErrorParams = {
        mustBeFrom: LOCALE_STRINGS[language].preOnboardingQuestionnaire_mustBeFrom,
        min: TEXT_FIELD_MIN_LENGTH,
        to: LOCALE_STRINGS[language].preOnboardingQuestionnaire_to,
        max: TEXT_FIELD_MAX_LENGTH,
        characters: LOCALE_STRINGS[language].preOnboardingQuestionnaire_characters
    };

    return (
        Yup.object().shape({
            message: Yup.string()
                .test(
                    "notOnlySpecialCharacters",
                    LOCALE_STRINGS[language].preOnboardingQuestionnaire_notOnlySpecialCharacters,
                    function (value) {
                        return NOT_ONLY_SPECIAL_CHARACTERS.test(value)
                    })
                .min(TEXT_FIELD_MIN_LENGTH, LOCALE_STRINGS[language].error_messageLength)
                .max(MESSAGE_MAX_LENGTH,
                    LENGTH_ERROR({
                        ...lengthErrorParams,
                        field: LOCALE_STRINGS[language].error_messageLength,
                    })
                )
                .required(`${LOCALE_STRINGS[language].error_messageRequired}`),
            email: Yup.string()
                .test(
                    "invalidEmail",
                    LOCALE_STRINGS[language].preOnboardingQuestionnaire_invalidEmail,
                    function (value) {
                        return EmailRegExp.test(value)
                    })
                .max(EMAIL_MAX_LENGTH,
                    `${LOCALE_STRINGS[language].preOnboardingQuestionnaire_email} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_mustBeNotLongerThan129Chars}`
                )
                .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_email} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
            contact: Yup.string()
                .min(PHONE_FIELD_MIN_LENGTH,
                    LOCALE_STRINGS[language].preOnboardingQuestionnaire_phoneMinLengthError
                )
                .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_phoneNumber} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        })
    );
}