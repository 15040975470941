import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export const CustomPhoneInput = ({ phone, updatePhone, onBlur, clearField }) => {
    return (
        <PhoneInput
            country={"us"}
            value={phone}
            onChange={(value, country, e) => {
                if (e.type === "click") {
                    clearField();
                    updatePhone(country.dialCode);
                } else {
                    updatePhone(value);
                }
            }}
            onBlur={onBlur}
            enableSearch
        />
    )
};
