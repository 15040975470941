import { Footer } from "components/Footer";
import { HeaderWithNav } from "components/HeaderWithNav";
import { HeroSection } from "components/HeroSection";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import heroImage from "assets/images/tbml-hero.png";
import { GetStartedSection } from "components/GetStartedSection";
import { MessageSection } from "./components/MessageSection";
import { LOCALE_STRINGS } from "constants/locales";
import { KeyPrinciplesSection } from "./components/KeyPrinciplesSection";
import { RestrictedSection } from "./components/RestrictedSection";
import { useScrollToElement } from "hooks/useScrollToElement";

export const Tbml = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    imageSrc={heroImage}
                    language={language}
                    titleKey={'hero_tbmlTitle'}
                    descriptionKey={'hero_tbmlDescription'}
                />
                <MessageSection
                    message={LOCALE_STRINGS[language].tbml_message}
                />
                <KeyPrinciplesSection
                    language={language}
                />
                <RestrictedSection
                    language={language}
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}