import { Footer } from "components/Footer";
import { HeaderWithNav } from "components/HeaderWithNav";
import { HeroSection } from "components/HeroSection";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import heroImage from "assets/images/solutions-hero.png";
import { GetStartedSection } from "components/GetStartedSection";
import cardImage1 from "assets/images/solution-image1.png";
import cardImage2 from "assets/images/solution-image2.png";
import { TextImageSection } from "pages/Products/components/TextImageSection";
import { HowItWorks } from "./components/HowItWorks";
import { LOCALE_STRINGS } from "constants/locales";
import { useScrollToElement } from "hooks/useScrollToElement";

export const Solutions = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    imageSrc={heroImage}
                    language={language}
                    titleKey={'hero_solutionsTitle'}
                    descriptionKey={'hero_solutionsDescription'}
                />
                <TextImageSection
                    title={LOCALE_STRINGS[language].solutions_block1_title}
                    content={LOCALE_STRINGS[language].solutions_block1_content}
                    contentNext={LOCALE_STRINGS[language].solutions_block1_contentNext}
                    image={cardImage1}
                    imageClassName="solutions-image"
                    className="solutions-text-section"
                    showMobileText
                />
                <HowItWorks
                    language={language}
                />
                <TextImageSection
                    title={LOCALE_STRINGS[language].solutions_block2_title}
                    content={LOCALE_STRINGS[language].solutions_block2_content}
                    contentNext={LOCALE_STRINGS[language].solutions_block2_contentNext}
                    language={language}
                    image={cardImage2}
                    imageClassName="solutions-image"
                    className="solutions-text-section reverse-text-image"
                    showMobileText
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}