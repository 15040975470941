export const UnlockingCard = ({
    img,
    content = ''
}) => {
    return (
        <div className="unlocking-card">
            <img
                alt=""
                src={img}
                className="w-24 h-24"
            />
            <p className="text-basic">
                {content}
            </p>
        </div>
    )
}