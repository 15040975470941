import { joinClassNames } from "helpers/joinClassNames"

export const TitleWithSubtitle = ({
    title,
    subtitle,
    className = ''
}) => {
    return (
        <div className={joinClassNames(
            "title-with-subtitle",
            className
        )}>
            <h2 className="title">
                {title}
            </h2>
            <p className="text-small">
                {subtitle}
            </p>
        </div>
    )
}