import { Footer } from "components/Footer";
import { HeaderWithNav } from "components/HeaderWithNav"
import { HeroSection } from "components/HeroSection";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import heroImage from "assets/images/products-hero.png";
import { GetStartedSection } from "components/GetStartedSection";
import { TextImageSection } from "./components/TextImageSection";
import { ExperienceSection } from "./components/ExperienceSection";
import { QuestionsSection } from "./components/QuestionsSection";
import image from 'assets/images/MacBookPro.png';
import { LOCALE_STRINGS } from "constants/locales";
import { useScrollToElement } from "hooks/useScrollToElement";

export const Products = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    imageSrc={heroImage}
                    language={language}
                    titleKey={'hero_productsTitle'}
                    descriptionKey={'hero_productsDescription'}
                />
                <TextImageSection
                    title={LOCALE_STRINGS[language].products_one_title}
                    content={LOCALE_STRINGS[language].products_one_content}
                    contentNext={LOCALE_STRINGS[language].products_one_content_next}
                    image={image}
                    className="px-10 py-20 w-full flex-center gap-8 mobile-products"
                />
                <ExperienceSection
                    language={language}
                />
                <QuestionsSection
                    language={language}
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}