import { Button } from "components/Button";
import { CustomInput } from "components/CustomInput";
import { CustomPhoneInput } from "components/CustomPhoneinput";
import { CustomTextarea } from "components/CustomTextarea";
import { FormItem } from "components/FormItem";
import { LOCALE_STRINGS } from "constants/locales";
import { EMAIL_MAX_LENGTH, MESSAGE_MAX_LENGTH } from "constants/validations";
import { Form, Formik } from "formik";
import { submitForm } from "helpers/contactUs";
import { contactValidationSchema } from "helpers/contactValidationSchema";

export const ContactForm = ({
    language = 'EN'
}) => {
    return (
        <div className="w-full">
            <Formik
                initialValues={{
                    email: "",
                    contact: "",
                    message: ""
                }}
                validationSchema={contactValidationSchema(language)}
                onSubmit={(values, { setSubmitting, setFieldValue, setFieldTouched }) => (
                    submitForm(values, setSubmitting, setFieldValue, setFieldTouched, language)
                )}
            >
                {({
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    validateField
                }) => {
                    return (
                        <Form className="form contact-form">
                            <div className="form__list flex flex-col w-full gap-6">
                                <FormItem
                                    label={LOCALE_STRINGS[language].contactUs_form_email}
                                    invalid={errors['email'] && touched['email']}
                                    errorMessage={errors['email']}
                                    key={'email'}
                                >
                                    <CustomInput
                                        type={'text'}
                                        autoComplete="off"
                                        name={'email'}
                                        placeholder={LOCALE_STRINGS[language].contactUs_form_email_placeholder}
                                        onChange={handleChange}
                                        onBlur={async (e) => {
                                            await setFieldValue('email', e.target.value.trim());
                                            validateField('email');
                                            handleBlur(e);
                                        }}
                                        value={values['email']}
                                        maxLength={EMAIL_MAX_LENGTH}
                                    />
                                </FormItem>

                                <FormItem
                                    label={LOCALE_STRINGS[language].contactUs_form_contact}
                                    invalid={errors.contact && touched.contact}
                                    errorMessage={errors.contact}
                                    key={'contact'}
                                >
                                    <CustomPhoneInput
                                        phone={values.phone}
                                        updatePhone={(value) => {
                                            setFieldValue("contact", value);
                                            setTimeout(() => setFieldTouched("contact", true), 0)
                                        }}
                                        onBlur={() => setFieldTouched("contact", true)}
                                        clearField={() => {
                                            setFieldValue("contact", "");
                                        }}
                                    />
                                </FormItem>

                                <FormItem
                                    label={LOCALE_STRINGS[language].contactUs_form_message}
                                    invalid={errors['message'] && touched['message']}
                                    errorMessage={errors['message']}
                                    className='contact-textarea'
                                    key={'message'}
                                >
                                    <CustomTextarea
                                        type={'text'}
                                        autoComplete="off"
                                        name={'message'}
                                        placeholder={LOCALE_STRINGS[language].contactUs_form_message_placeholder}
                                        onChange={handleChange}
                                        onBlur={async (e) => {
                                            await setFieldValue('message', e.target.value.trim());
                                            validateField('message');
                                            handleBlur(e);
                                        }}
                                        value={values['message']}
                                        maxLength={MESSAGE_MAX_LENGTH}
                                    />
                                </FormItem>
                            </div>
                            <Button
                                btnText={LOCALE_STRINGS[language].contactUs_submitButton}
                                type="submit"
                                className="contact-submit"
                                isDisabled={isSubmitting}
                                isLoading={isSubmitting}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}