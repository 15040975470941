import { joinClassNames } from "helpers/joinClassNames"

export const ProductCard = ({
    img,
    title = '',
    content = '',
    imgClassName = '',
    className = ''
}) => {
    return (
        <div className={joinClassNames(
            "card px-12-5 py-12-5 w-full flex-col gap-6",
            className
        )}>
            <img
                alt=""
                className={imgClassName}
                src={img}
            />
            <div className="w-full flex-col">
                <p className="subtitle mb-3">
                    {title}
                </p>
                <p className="text-smaller">
                    {content}
                </p>
            </div>
        </div>
    )
}