import { LocalizationContext } from "contexts/localizationContext";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";


export const useDynamicLanguage = () => {
    const { language: contextLanguage, changeLanguage } = useContext(LocalizationContext);
    const query = new URLSearchParams(useLocation().search);
    const lang = query.get('lang');

    const language = contextLanguage ?? "EN";

    useEffect(() => {
        if (!lang) return;
        changeLanguage(lang.toUpperCase());
    }, [changeLanguage, lang]);

    return language;
}