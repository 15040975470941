import { useState } from "react"
import arrow from 'assets/icons/Arrow.svg';
import { joinClassNames } from "helpers/joinClassNames";
import useWindowSize from "hooks/useWindowSize";
import { MOBILE_FOOTER, MOBILE_QUESTION_MARGIN, QUESTION_MARGIN } from "constants/media";

export const QuestionCard = ({
    title = '',
    content = ''
}) => {
    const [isOpen, updateIsOpen] = useState(false);
    const { width } = useWindowSize();

    const toggleDropdown = () => updateIsOpen(state => !state);

    const isMobile = width <= MOBILE_FOOTER;
    const marginHeight = isMobile ? MOBILE_QUESTION_MARGIN : QUESTION_MARGIN;

    const height = isOpen ? 'auto' : '0px';
    const marginTop = isOpen ? `${marginHeight}px`: '0px';

    return (
        <div 
            className={joinClassNames(
                "flex-col question-card"
            )}
            onClick={toggleDropdown}
        >
            <div
                className="text-question flex justify-between"
            >
                {title}
                <div className='w-[22px] h-[30px]'>
                    <img
                        src={arrow}
                        alt=""
                        className={joinClassNames(
                            isOpen ? 'rotate-180' : 'rotate-0'
                        )}
                    />
                </div>
            </div>
            <div
                className="question-dropdown text-smaller"
                style={{
                    height,
                    marginTop
                }}
            >
                {content}
            </div>
        </div>
    )
}