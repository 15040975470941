import { ToastService } from "components/ToastElement";
import { CONTACT_URL } from "constants/api";
import { LOCALE_STRINGS } from "constants/locales";

export const submitForm = async (values, setSubmitting, setFieldValue, setFieldTouched, language) => {
    const body = JSON.stringify({
        email: values.email.trim(),
        contact: `+${values.contact.trim()}`,
        message: values.message.trim()
    });

    return fetch(CONTACT_URL, {
        method: 'POST',
        body,
        headers: {
            "Accept": "application/json",
            'Content-type': 'application/json;charset=utf-8',
            'Accept-Language': `${language}`.toLowerCase()
        },
    }).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return Promise.reject(response);
    })
    .then(() => {
        setFieldValue('message', '');
        setFieldTouched('message', false);
        ToastService.success(LOCALE_STRINGS[language].contactUs_message);
    })
    .catch((response) => {
        response.json().then((json) => {
            json.errors?.forEach(err => ToastService.error(err.message))
        })

    })
    .finally(() => setSubmitting(false));
}