import React, {useContext, useMemo} from "react";
import Select, {components} from "react-select";

import {LOCALE_OPTIONS, LOCALE_STRINGS} from "constants/locales";
import {LocalizationContext} from "contexts/localizationContext";
import {Icon} from "components/Icon";
import {ICONS} from "constants/icons";

const { Option, ValueContainer } = components;

const customStyle = {
    input: (baseStyles) => ({
        ...baseStyles,
        color: 'transparent',
        caretColor: 'transparent'
    })
};

function IconOption(props) {
    const { data: { label, imgSrc, imgAlt } } = props;

    return (
        <Option {...props}>
            <div className="languageSelect__iconOption">
                {imgSrc && <img src={imgSrc} alt={imgAlt} className="languageSelect__iconOption__img" />}
                <span>{label}</span>
            </div>
        </Option>
    );
}

const IconValueContainer = ({ children, ...props }) => {
    const { selectProps: { value } } = props;
    let selectInput = React.Children.toArray(children).find((input) => input.type.name === "Input" || input.type.name === "DummyInput");

    return <ValueContainer inputMode="none" {...props}>
        <div className="languageSelect__iconValueContainer">
            {value.imgSrc && <img
                src={value.imgSrc}
                alt={value.imgAlt}
                className="languageSelect__iconValueContainer__img"
            />}
            <span>{value.label}</span>
            {selectInput}
        </div>
    </ValueContainer>
}

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <div className={props.selectProps.menuIsOpen
                ? "languageSelect__arrow-container menu-open"
                : "languageSelect__arrow-container"
            }>
                <Icon icon={ICONS.selectArrow} />
            </div>
        </components.DropdownIndicator>
    );
};

export const LanguageSelect = () => {
    const { language, changeLanguage } = useContext(LocalizationContext);

    const handleLanguageChange = (lang) => {
        changeLanguage(lang.value);
    };

    const translatedLocaleOptions = useMemo(() => {
        return LOCALE_OPTIONS.map(option => (
            {
                ...option,
                label: LOCALE_STRINGS[language][option.label]
            }
        ));
    }, [language]);

    const selectedLanguage = useMemo(() => {
        return language ? translatedLocaleOptions.filter(langObj => langObj.value === language)?.[0] : translatedLocaleOptions[0];
    }, [language, translatedLocaleOptions]);


    return (
        <Select
            className="basic-single language-select"
            classNamePrefix="languageSelect"
            value={selectedLanguage}
            name="lang"
            options={translatedLocaleOptions}
            components={{
                Option: IconOption,
                ValueContainer: IconValueContainer,
                IndicatorSeparator: () => null,
                DropdownIndicator
            }}
            onChange={handleLanguageChange}
            hideSelectedOptions={true}
            styles={customStyle}
        />

    )
}
