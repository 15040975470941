import React, {useContext, useMemo, useState} from "react";
import {Form, Formik} from "formik";
import Select, {components} from "react-select";

import {LocalizationContext} from "contexts/localizationContext";
import {LOCALE_STRINGS} from "constants/locales";
import {FormItem} from "components/FormItem";
import {Button} from "components/Button";
import {CustomInput} from "components/CustomInput";
import {questionnaireValidationSchema} from "helpers/questionnaireValidationSchema";
import {preOnboardingQuestionnaireFields} from "constants/preOnboardingQuestionnaireFields";
import {Icon} from "components/Icon";
import {ICONS} from "constants/icons";
import {CustomPhoneInput} from "components/CustomPhoneinput";
import {submitForm} from "helpers/preOnboardingQuestionnaire";
import { countries } from "constants/countries";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "constants/navigation";


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon icon={ICONS.selectArrow} />
        </components.DropdownIndicator>
    );
};

const customStyle = {
    dropdownIndicator: (base, state) => ({
        ...base,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    })
};

export const PreOnboardingQuestionnaire = ({ closeModal }) => {
    const { language } = useContext(LocalizationContext);
    const query = new URLSearchParams(useLocation().search);
    const email = query.get('email');
    const businessName = query.get('name');
    
    const [isDeclined, setIsDeclined] = useState(false);

    const translatedCountries = useMemo(() => {
        return countries.map(({ value }) => (
            {
                value,
                label: value
            }
        ));
    }, []);

    return (
        <div className="preOnboardingQuestionnaire">
            <p className="preOnboardingQuestionnaire__title">
                {LOCALE_STRINGS[language].preOnboardingQuestionnaire_title}
            </p>
            <Formik
                initialValues={{
                    businessName: businessName ?? "",
                    yearsInBusiness: "",
                    annualSales: "",
                    tradeCommodity: "",
                    email: email ?? "",
                    phone: "",
                    country: ""
                }}
                validationSchema={questionnaireValidationSchema(language)}
                onSubmit={(values, { setSubmitting }) => submitForm(
                    values, 
                    setSubmitting, 
                    language, 
                    closeModal,
                    () => setIsDeclined(true)
                )}
            >
                {({
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    validateField
                }) => {
                    return (
                        <Form className="form">
                            <div className="form__list">
                                {preOnboardingQuestionnaireFields.map(({
                                    label,
                                    placeholder,
                                    name,
                                    labelSuffix,
                                    placeholderSuffix,
                                    type,
                                    maxLength,
                                    required
                                }) => (
                                    <FormItem
                                        label={LOCALE_STRINGS[language][label]}
                                        invalid={errors[name] && touched[name]}
                                        errorMessage={errors[name]}
                                        key={name}
                                        labelSuffix={labelSuffix}
                                        asterisk={required}
                                    >
                                        <CustomInput
                                            type={type}
                                            autoComplete="off"
                                            name={name}
                                            placeholder={LOCALE_STRINGS[language][placeholder]}
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            onBlur={async (e) => {
                                                await setFieldValue(name, e.target.value.trim());
                                                validateField(name);
                                                handleBlur(e);
                                            }}
                                            value={values[name]}
                                            placeholderSuffix={placeholderSuffix}
                                            maxLength={maxLength}
                                        />
                                    </FormItem>
                                ))}
                                <FormItem
                                    label={LOCALE_STRINGS[language].preOnboardingQuestionnaire_phoneNumber}
                                    invalid={errors.phone && touched.phone}
                                    errorMessage={errors.phone}
                                    asterisk={true}
                                >
                                    <CustomPhoneInput
                                        phone={values.phone}
                                        updatePhone={(value) => {
                                            setFieldValue("phone", value);
                                            setTimeout(() => setFieldTouched("phone", true), 0)
                                        }}
                                        onBlur={() => setFieldTouched("phone", true)}
                                        clearField={() => {
                                            setFieldValue("phone", "");
                                        }}
                                    />
                                </FormItem>
                                <FormItem
                                    label={LOCALE_STRINGS[language].preOnboardingQuestionnaire_country}
                                    invalid={errors.country && touched.country}
                                    errorMessage={errors.country}
                                    asterisk={true}
                                >
                                    <Select
                                        className="basic-single absolute country-selector"
                                        classNamePrefix="countrySelect"
                                        placeholder={LOCALE_STRINGS[language].preOnboardingQuestionnaire_country}
                                        name="lang"
                                        options={translatedCountries}
                                        components={{
                                            IndicatorSeparator: () => null,
                                            DropdownIndicator
                                        }}
                                        onChange={option => {
                                            setFieldValue("country", option.value).then(() => setFieldTouched("country", true))
                                        }}
                                        styles={customStyle}
                                        maxMenuHeight={290}
                                        menuPlacement="top"
                                        onBlur={() => setFieldTouched("country", true)}
                                        isSearchable={true}
                                    />
                                </FormItem>
                            </div>

                            {isDeclined && (
                                <div className="decline_form">
                                    <div className="decline_form_text">
                                        {LOCALE_STRINGS[language].declined_form}
                                    </div>

                                    <Link
                                        className="decline_form_link"
                                        to={ROUTES.contactUs.path}
                                    >
                                        {LOCALE_STRINGS[language].contactUs_link}
                                    </Link>
                                </div>
                            )}

                            <Button
                                btnText={LOCALE_STRINGS[language].preOnboardingQuestionnaire_submit}
                                type="submit"
                                className="form__submit-button"
                                isDisabled={isSubmitting}
                                isLoading={isSubmitting}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
};
