import { joinClassNames } from "helpers/joinClassNames";

export const TextImageSection = ({
    image,
    title = '',
    content = '',
    contentNext = '',
    className,
    imageClassName = ''
}) => {
    return (
        <section className={className ?? "px-10 py-20 w-full flex-center gap-8"}>
            <div className='flex-col gap-4 text-image-section-block'>
                <div className='subtitle'>
                    {title}
                </div>
                <div className='flex-col text-lightGray'>
                    <p className='text-image-section-paragraph mb-3-5'>
                        {content}
                    </p>
                    <p className='text-image-section-paragraph'>
                        {contentNext}
                    </p>
                </div>
            </div>
            <img
                alt=''
                src={image}
                className={joinClassNames(
                    'product-page-image',
                    imageClassName
                )}
            />
        </section>
    )
}