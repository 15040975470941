import {useContext, useEffect} from "react";

import {Button} from "components/Button";
import {LOCALE_STRINGS} from "constants/locales";
import {LocalizationContext} from "contexts/localizationContext";
import {useModalDialog} from "hooks/useModalDialog";
import {Modal} from "components/Modal";

import {PreOnboardingQuestionnaire} from "./PreOnboardingQuestionnaire";
import { joinClassNames } from "helpers/joinClassNames";

export const GetStartedButtonWithModal = ({
    className = ''
}) => {
    const { language } = useContext(LocalizationContext);

    const { isOpen, open, close } = useModalDialog();

    useEffect( () => {
        if(isOpen){
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open")
        }
    }, [isOpen]);

    return (
        <>
            <Button
                btnText={LOCALE_STRINGS[language].getStarted}
                className={joinClassNames(
                    "home-page__getStartedButton mobile-full",
                    className
                )}
                onClick={open}
            />
            {isOpen ? (
                <Modal onClose={close} overlayClass="home-page__form-modal">
                    <PreOnboardingQuestionnaire closeModal={close}/>
                </Modal>
            ) : null}
        </>
    );
};
