import { TitleWithSubtitle } from "components/TitleWithSubtitle"
import { LOCALE_STRINGS } from "constants/locales"
import unlockingImage from 'assets/images/UnlockingImage.png'
import { UnlockingCard } from "./UnlockingCard"
import unlockingImg1 from 'assets/images/UnlockingImg1.svg'
import unlockingImg2 from 'assets/images/UnlockingImg2.svg'
import unlockingImg3 from 'assets/images/UnlockingImg3.svg'
import unlockingImg4 from 'assets/images/UnlockingImg4.svg'

export const UnlockingSection = ({
    language = 'EN'
}) => {
    return (
        <section className="limit-section pt-20 flex-col items-center">
            <TitleWithSubtitle
                title={LOCALE_STRINGS[language].home_unlocking_title}
                subtitle={LOCALE_STRINGS[language].home_unlocking_subtitle}
                className="mb-12 max-w-150 text-center"
            />
            <div className="flex gap-5 two-cards unlock-block">
                <img
                    alt=""
                    src={unlockingImage}
                    className="unlock-image"
                />
                <div className="unlocking-cards">
                    <UnlockingCard
                        img={unlockingImg1}
                        content={LOCALE_STRINGS[language].home_unlocking_card1}
                    />
                    <UnlockingCard
                        img={unlockingImg2}
                        content={LOCALE_STRINGS[language].home_unlocking_card2}
                    />
                    <UnlockingCard
                        img={unlockingImg3}
                        content={LOCALE_STRINGS[language].home_unlocking_card3}
                    />
                    <UnlockingCard
                        img={unlockingImg4}
                        content={LOCALE_STRINGS[language].home_unlocking_card4}
                    />
                </div>
            </div>
        </section>
    )
}