import { joinClassNames } from "./joinClassNames";

export const parseLines = (content = '') => {
    const lines = content.split('\n').map((line, index) => {
        return <div key={line} className={joinClassNames(
            "line",
            `line-${index}`
        )}>{line}</div>;
    });

    return lines;
}