import { KeyPrincipleCard } from "pages/TBML/components/KeyPrincipleCard";
import stand1 from 'assets/images/stand1.svg';
import stand2 from 'assets/images/stand2.svg';
import stand3 from 'assets/images/stand3.svg';
import { LOCALE_STRINGS } from "constants/locales";

export const StandsSection = ({
    language = 'EN'
}) => {
    return (
        <section className="stands-section">
            <KeyPrincipleCard
                img={stand1}
                title={LOCALE_STRINGS[language].aboutUs_stand1_title}
                content={LOCALE_STRINGS[language].aboutUs_stand1_content}
                className="stand-card"
                imgClassName="mb-3"
            />
            <KeyPrincipleCard
                img={stand2}
                title={LOCALE_STRINGS[language].aboutUs_stand2_title}
                content={LOCALE_STRINGS[language].aboutUs_stand2_content}
                className="stand-card"
                imgClassName="mb-3"
            />
            <KeyPrincipleCard
                img={stand3}
                title={LOCALE_STRINGS[language].aboutUs_stand3_title}
                content={LOCALE_STRINGS[language].aboutUs_stand3_content}
                className="stand-card"
                imgClassName="mb-3"
            />
        </section>
    )
}