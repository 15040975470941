export const ICONS = {
    logo: "logo",
    africa: "africa",
    bulbOutline: "bulbOutline",
    cashOutline: "cashOutline",
    shieldCheckmarkOutline: "shieldCheckmarkOutline",
    selectArrow: "selectArrow",
    smallRings: "smallRings",
    largeRings: "largeRings",
    albumsOutline: "albumsOutline",
    analytics: "analytics",
    barChartOutline: "barChartOutline",
    readerOutline: "readerOutline",
    spiral: "spiral",
    menu: "menu",
    close: "close",
    toastError: "toastError",
    toastSuccess: "toastSuccess"
};
