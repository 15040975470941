import { LOCALE_STRINGS } from 'constants/locales';
import { MOBILE } from 'constants/media';
import useWindowSize from 'hooks/useWindowSize';

export const ContactMap = ({
    language = 'EN'
}) => {
    const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3104.872984203359!2d-77.0452025!3d38.9040198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b7b763155555%3A0x2916042519bce78f!2s2001%20L%20St%20NW%20Suite%20500%2C%20Washington%2C%20DC%2020036%2C%20%D0%A1%D0%A8%D0%90!4v1712747849756";

    const { width } = useWindowSize();
    const isMobile = width <= MOBILE;

    return (
        <div className="contact-map-section">
            <iframe
                title='map'
                src={mapSrc}
                width={isMobile ? `${width}` : "555"}
                height={isMobile ? 'auto' : "405"}
                className='contact-map'
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className='contact-address'>
                <div className='flex flex-col gap-2'>
                    <div className='text-tiny max-w-51'>
                        {LOCALE_STRINGS[language].contactUs_officeTitle}
                    </div>
                    <div className='text-tiny color-dark max-w-51'>
                        {LOCALE_STRINGS[language].contactUs_office}
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    <div className='text-tiny max-w-51'>
                        {LOCALE_STRINGS[language].contactUs_emailTitle}
                    </div>
                    <div className='text-tiny color-dark max-w-51'>
                        {LOCALE_STRINGS[language].contactUs_email}
                    </div>
                </div>
            </div>
        </div>
    )
}