import { AboutUs } from "pages/AboutUs"
import { ContactUs } from "pages/ContactUs"
import { Esg } from "pages/ESG"
import { Home } from "pages/Home"
import { PrivacyPolicy } from "pages/PrivacyPolicy"
import { Products } from "pages/Products"
import { Solutions } from "pages/Solutions"
import { Tbml } from "pages/TBML"
import { TermsOfUse } from "pages/TermsOfUse"

export const ROUTES = {
    root: {
        path: '/',
        name: "home",
        title: "nav_home",
        element: <Home />
    },
    products: {
        path: '/products',
        name: "products",
        title: "nav_products",
        element: <Products />
    },
    solutions: {
        path: '/solutions',
        name: "solutions",
        title: "nav_solutions",
        element: <Solutions />
    },
    aboutUs: {
        path: '/about-us',
        name: "aboutUs",
        title: "nav_aboutUs",
        element: <AboutUs />
    },
    sustainability: {
        path: '/sustainability',
        name: "sustainability",
        title: "nav_sustainability",
        element: <Esg />
    },
    tbml: {
        path: '/tbml',
        name: "tbml",
        title: "nav_tbml",
        element: <Tbml />
    },
    contactUs: {
        path: '/contact-us',
        name: "contactUs",
        title: "nav_contacts",
        element: <ContactUs />
    },
    termsOfUse: {
        path: '/terms-of-use',
        name: "termsOfUse",
        title: "nav_solutions",
        element: <TermsOfUse />
    },
    privacyPolicy: {
        path: '/privacy-policy',
        name: "privacyPolicy",
        title: "nav_solutions",
        element: <PrivacyPolicy />
    }
}

export const HEADER_NAVIGATION = [
    ROUTES.root,
    ROUTES.products,
    ROUTES.solutions,
    ROUTES.aboutUs,
    ROUTES.sustainability,
    ROUTES.contactUs
]

export const FOOTER_NAVIGATION = [
    ROUTES.products,
    ROUTES.solutions,
    ROUTES.aboutUs,
    ROUTES.sustainability,
    ROUTES.tbml,
    ROUTES.contactUs
]

export const MOBILE_FOOTER_NAVIGATION = [
    ROUTES.root,
    ROUTES.products,
    ROUTES.solutions,
    ROUTES.aboutUs,
    ROUTES.sustainability,
    ROUTES.tbml,
    ROUTES.contactUs
]

export const HOME_PAGE_SECTIONS = {
    solutions: {
        id: "solutions",
        title: "homePage_nav_solutions"
    },
    howItWorks: {
        id: "how-it-works",
        title: "homePage_nav_howItWorks"
    },
    contacts: {
        id: "contacts",
        title: "homePage_nav_contacts"
    }
}
