import {useContext} from "react";

import {Icon} from "components/Icon";
import {ICONS} from "constants/icons";
import logoTitle from "assets/images/logo-title.png";
import {LocalizationContext} from "contexts/localizationContext";
import {LOCALE_STRINGS} from "../../constants/locales";

export const TermsOfUseAndPrivacyPolicyFooter = ({ children }) => {
    const { language } = useContext(LocalizationContext);

    const today = new Date();

    return (
        <footer className="termsOfUseAndPrivacyPolicy__footer">
            <div className="termsOfUseAndPrivacyPolicy__footer__content">
                <section className="termsOfUseAndPrivacyPolicy__footer__content__first-row">
                    <a href="#" className="termsOfUseAndPrivacyPolicy__footer__logo">
                        <Icon icon={ICONS.logo} className="termsOfUseAndPrivacyPolicy__footer__logo__icon" />
                        <img src={logoTitle} alt="Vantage 360 logo"
                             className="termsOfUseAndPrivacyPolicy__footer__logo__title"></img>
                    </a>
                    <address className="termsOfUseAndPrivacyPolicy__footer__email">
                        <a className="termsOfUseAndPrivacyPolicy__footer__email__link"
                           href="mailto:info@vantage-360.com">info@vantage-360.com</a>
                    </address>
                    {children}
                </section>
                <section className="termsOfUseAndPrivacyPolicy__footer__content__second-row">
                    <span className="termsOfUseAndPrivacyPolicy__footer__rights">
                        © {today.getFullYear()} {LOCALE_STRINGS[language].allRightsReserved || "All rights reserved"}
                    </span>
                </section>
            </div>
        </footer>
    )
};
