import {useContext} from "react";
import {Link} from "react-router-dom";

import {LocalizationContext} from "contexts/localizationContext";
import {HOME_PAGE_SECTIONS, FOOTER_NAVIGATION, ROUTES, MOBILE_FOOTER_NAVIGATION} from "constants/navigation";
import logoBlueAndWhite from "assets/images/logo-blue-and-white.png";
import {LanguageSelect} from "components/LanguageSelect";
import {LOCALE_STRINGS} from "constants/locales";
import linkedIcon from 'assets/icons/Linkedin.svg';
import mailIcon from 'assets/icons/Mail.svg';
import { ContactLink } from "./ContactLink";
import useWindowSize from "hooks/useWindowSize";
import { MOBILE_FOOTER } from "constants/media";
import { Icon } from "components/Icon";
import { ICONS } from "constants/icons";
import logoTitleWhiteOnBlue from "assets/images/footer-logo.png";

export const Footer = () => {
    const { language } = useContext(LocalizationContext);

    const { width } = useWindowSize();

    const isMobile = width <= MOBILE_FOOTER;
    const isRoot = window.location.pathname === ROUTES.root.path;

    const scrollPage = () => {
        const element = document.querySelector('#page');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }  
    }

    return (
        <footer className="footer" id={HOME_PAGE_SECTIONS.contacts.id}>
            {isMobile ? (
                <div className="footer__content mobile-footer">
                    {isRoot ? (
                        <button
                            onClick={scrollPage}
                            className="mobile-footer-logo"
                        >
                            <Icon icon={ICONS.logo} className="home-page__getStarted__logo__icon"/>
                            <img src={logoTitleWhiteOnBlue} alt="Vantage 360 logo" className="home-page__getStarted__logo__title"></img>
                        </button>
                    ) : (
                        <Link 
                            rel="noopener noreferrer"
                            to={`${ROUTES.root.path}#page`} 
                            className="mobile-footer-logo"
                        >
                            <Icon icon={ICONS.logo} className="home-page__getStarted__logo__icon"/>
                            <img src={logoTitleWhiteOnBlue} alt="Vantage 360 logo" className="home-page__getStarted__logo__title"></img>
                        </Link>
                    )}
                    <ul className="mobile-links">
                        {MOBILE_FOOTER_NAVIGATION.map((route) => {
                            const isCurrentPath = route.path === window.location.pathname;

                            return (
                                isCurrentPath ? (
                                    <button
                                        key={`mobileFooter${route.path}`}
                                        onClick={scrollPage}
                                        className="footer__links__item nav-button"
                                    >
                                        {LOCALE_STRINGS[language][route.title]}
                                    </button>
                                ) : (
                                    <Link
                                        key={`mobileFooter${route.path}`}
                                        to={`${route.path}#page`} 
                                        className="footer__links__item"
                                        rel="noopener noreferrer"
                                    >
                                        {LOCALE_STRINGS[language][route.title]}
                                    </Link>
                                )
                            )
                        })}
                    </ul>
                    <address className="footer__address">
                        <span className="footer__address__label">{LOCALE_STRINGS[language].address}</span>
                        <a
                            className="footer__address__link"
                            href="https://maps.app.goo.gl/HXfz4ryJecixc4M17"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p className="footer__address__row">{LOCALE_STRINGS[language].address_first}</p>
                            <p className="footer__address__row">{LOCALE_STRINGS[language].address_second}</p>
                        </a>
                    </address>
                    <address className="footer__email">
                        <span className="footer__email__label">{LOCALE_STRINGS[language].contactUs}</span>
                        <div className="footer-contacts">
                            <div className="footer-contact">
                                <a className="footer__email__link" href={'mailto:info@vantage-360.com'}>
                                    info@vantage-360.com
                                </a>
                            </div>
                            <div className="footer-contact">
                                <a className="footer__email__link" href={'https://www.linkedin.com/company/vantage-360-inc/'}>
                                    LinkedIn
                                </a>
                            </div>
                        </div>
                    </address>
                    <ul className="footer-mobile-terms">
                        <Link
                            to={ROUTES.termsOfUse.path} className="footer__links__item mobile-term-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {LOCALE_STRINGS[language][ROUTES.termsOfUse.name]}
                        </Link>
                        <Link
                            to={ROUTES.privacyPolicy.path} className="footer__links__item mobile-term-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {LOCALE_STRINGS[language][ROUTES.privacyPolicy.name]}
                        </Link>
                    </ul>
                    <div className="footer-language">
                        <LanguageSelect/>
                    </div>
                </div>
            ) : (
                <div className="footer__content">
                    <div className="footer-logo">
                        {isRoot ? (
                            <button
                                onClick={scrollPage}
                                className="header__logo"
                            >
                                <img src={logoBlueAndWhite} alt="Vantage 360 logo" className="footer__logo"/>
                            </button>
                        ) : (
                            <Link 
                                rel="noopener noreferrer"
                                to={`${ROUTES.root.path}#page`} 
                                className="header__logo"
                            >
                                <img src={logoBlueAndWhite} alt="Vantage 360 logo" className="footer__logo"/>
                            </Link>
                        )}
                        <div className="footer-language">
                            <LanguageSelect/>
                        </div>
                    </div>
                    <nav className="footer-nav">
                        <ul className="footer-top-links">
                            {FOOTER_NAVIGATION.map((route) => {
                                const isCurrentPath = route.path === window.location.pathname;

                                return (
                                    isCurrentPath ? (
                                        <button
                                            key={`footer${route.path}`}
                                            onClick={scrollPage} 
                                            className="footer__links__item nav-button"
                                        >
                                            {LOCALE_STRINGS[language][route.title]}
                                        </button>
                                    ) : (
                                        <Link
                                            key={`footer${route.path}`}
                                            to={`${route.path}#page`} 
                                            className="footer__links__item"
                                            rel="noopener noreferrer"
                                        >
                                            {LOCALE_STRINGS[language][route.title]}
                                        </Link>
                                    )
                                )
                            })}
                        </ul>
                        <ul className="footer-top-links">
                            <Link
                                to={ROUTES.termsOfUse.path} className="footer__links__item"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {LOCALE_STRINGS[language][ROUTES.termsOfUse.name]}
                            </Link>
                            <Link
                                to={ROUTES.privacyPolicy.path} className="footer__links__item"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {LOCALE_STRINGS[language][ROUTES.privacyPolicy.name]}
                            </Link>
                        </ul>
                    </nav>
                    <address className="footer__email">
                        <span className="footer__email__label">{LOCALE_STRINGS[language].contactUs}</span>
                        <div className="footer-contacts">
                            <ContactLink
                                icon={mailIcon}
                                label='info@vantage-360.com'
                                link='mailto:info@vantage-360.com'
                            />
                            <ContactLink
                                icon={linkedIcon}
                                label='LinkedIn'
                                link='https://www.linkedin.com/company/vantage-360-inc/'
                            />
                        </div>
                    </address>
                    <address className="footer__address">
                        <span className="footer__address__label">{LOCALE_STRINGS[language].address}</span>
                        <a
                            className="footer__address__link"
                            href="https://maps.app.goo.gl/HXfz4ryJecixc4M17"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <p className="footer__address__row">{LOCALE_STRINGS[language].address_first}</p>
                            <p className="footer__address__row">{LOCALE_STRINGS[language].address_second}</p>
                        </a>
                    </address>
                </div>
            )}
        </footer>
    )
}
