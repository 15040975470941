import { Footer } from "components/Footer";
import { HeaderWithNav } from "components/HeaderWithNav";
import { HeroSection } from "components/HeroSection";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import heroImage from "assets/images/ESG-hero.png";
import { GetStartedSection } from "components/GetStartedSection";
import { SustainabilitySection } from "./components/SustainabilitySection";
import { CommitmentSection } from "./components/CommitmentSection";
import { useScrollToElement } from "hooks/useScrollToElement";

export const Esg = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    imageSrc={heroImage}
                    language={language}
                    titleKey={'hero_esgTitle'}
                    descriptionKey={'hero_esgDescription'}
                />
                <CommitmentSection
                    language={language}
                />
                <SustainabilitySection
                    language={language}
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}