export const ExperienceCard = ({
    image,
    content = ''
}) => {
    return (
        <div className="experience-card">
            <img
                src={image}
                alt=""
            />
            <div className="experience-content">
                {content}
            </div>
        </div>
    )
}