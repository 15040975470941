import {joinClassNames} from "helpers/joinClassNames";

export const FormItem = ({ label, invalid, errorMessage, children, labelSuffix = "", asterisk = false }) => {
    return (
        <div className="form__list__item">
            <label className="form__list__item__label">
                {label}
                {labelSuffix && ` ${labelSuffix}`}
                {asterisk && <div className="form__list__item__label__required">*</div>}
            </label>
            <div className={joinClassNames(
                "form__list__item__input-container",
                invalid ? "form-error" : ""
            )}>
                {children}
                {invalid && <div className="form__list__item__input-container__error">{errorMessage}</div>}
            </div>
        </div>
    );
};
