import { LOCALE_STRINGS } from "constants/locales";
import step1 from 'assets/images/step-1.svg';
import step2 from 'assets/images/step-2.svg';
import step3 from 'assets/images/step-3.svg';
import step4 from 'assets/images/step-4.svg';
import mobileStep1 from 'assets/images/mobile-step1.svg';
import mobileStep2 from 'assets/images/mobile-step2.svg';
import mobileStep3 from 'assets/images/mobile-step3.svg';
import mobileStep4 from 'assets/images/mobile-step4.svg';
import howItWorksImage1 from 'assets/images/hiw-1.svg';
import howItWorksImage2 from 'assets/images/hiw-2.svg';
import howItWorksImage3 from 'assets/images/hiw-3.svg';
import howItWorksImage4 from 'assets/images/hiw-4.svg';
import { HowItWorksFlowCard } from "./HowItWorksFlowCard";
import line1 from 'assets/images/Line1.svg';
import line2 from 'assets/images/Line2.svg';
import mobileLine from 'assets/images/mobile-line.svg';
import useWindowSize from "hooks/useWindowSize";
import { MOBILE_FOOTER } from "constants/media";
import { joinClassNames } from "helpers/joinClassNames";


const STEPS = [
    {
        title: 'solutions_step1_title',
        content: 'solutions_step1_content',
        stepImg: step1,
        mobileImg: mobileStep1,
        image: howItWorksImage1,
        className: 'odd-step'
    },
    {
        title: 'solutions_step2_title',
        content: 'solutions_step2_content',
        stepImg: step2,
        mobileImg: mobileStep2,
        image: howItWorksImage2,
        className: 'even-step row-reverse text-right'
    },
    {
        title: 'solutions_step3_title',
        content: 'solutions_step3_content',
        stepImg: step3,
        mobileImg: mobileStep3,
        image: howItWorksImage3,
        className: 'odd-step'
    },
    {
        title: 'solutions_step4_title',
        content: 'solutions_step4_content',
        stepImg: step4,
        mobileImg: mobileStep4,
        image: howItWorksImage4,
        className: 'even-step row-reverse text-right'
    }
]

export const HowItWorks = ({
    language = 'EN'
}) => {
    const { width } = useWindowSize();
    const isMobile = width <= MOBILE_FOOTER;

    return (
        <section className="how-it-works-section w-full">
            <div className="card how-it-works">
                <div className="flex-col gap-3 text-center">
                    <h2 className='title'>
                        {LOCALE_STRINGS[language].solutions_howItWorks}
                    </h2>
                    <div className="text-small">
                        {LOCALE_STRINGS[language].solutions_HowItWorksDescription}
                    </div>
                </div>
                {isMobile ? (
                    <div className="mobile-how-it-works-flow">
                        <div className="mobile-how-it-works-lines">
                            <img
                                alt=""
                                src={mobileLine}
                            />
                        </div>
                        {STEPS.map(({ title, content, mobileImg, className }) => (
                            <div
                                key={title}
                                className={joinClassNames(
                                    "step-line",
                                    className
                                )}
                            >
                                <img
                                    alt=""
                                    src={mobileImg}
                                    className="how-it-works-step"
                                />
                                <div className="w-112">
                                    <div className="text-basic mb-1">
                                        {LOCALE_STRINGS[language][title]}
                                    </div>
                                    <div className="text-small-alt">
                                        {LOCALE_STRINGS[language][content]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="how-it-works-flow">
                        <div className="how-it-works-lines">
                            <img
                                alt=""
                                src={line2}
                            />
                            <img
                                alt=""
                                src={line1}
                                className="how-it-works-line1"
                            />
                        </div>
                        {STEPS.map(({ title, content, stepImg, image, className }) => (
                            <HowItWorksFlowCard
                                key={title}
                                title={LOCALE_STRINGS[language][title]}
                                content={LOCALE_STRINGS[language][content]}
                                stepImg={stepImg}
                                image={image}
                                className={className}
                            />
                        ))}
                    </div>
                )}
            </div>
        </section>
    )
}