import ReactDOM from "react-dom";

import {Button} from "components/Button";
import {ICONS} from "constants/icons";
import {joinClassNames} from "helpers/joinClassNames";

export const Modal = ({
    onClose,
    children,
    overlayClass = "",
}) => {
    const onContentClick = (e) => {
        e.stopPropagation();
    };

    const modalContent = (
        <div className={joinClassNames("modal-overlay", overlayClass)} onClick={onClose}>
            <div className="modal-container" onClick = {onContentClick}>
                <div className="modal__header">
                    <Button
                        className="modal__close"
                        icon={ICONS.close}
                        onClick={onClose}
                    />
                </div>
               <div className="modal-content">
                   {children}
               </div>
            </div>
        </div>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.getElementById("modal-root"),
    );
};
