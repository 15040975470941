import { joinClassNames } from "helpers/joinClassNames"

export const HowItWorksFlowCard = ({
    title = '',
    content = '',
    stepImg,
    image,
    className = ''
}) => {
    return (
        <div className={joinClassNames(
            "step-line",
            className
        )}>
            <img
                alt=""
                src={image}
            />
            <img
                alt=""
                src={stepImg}
                className="how-it-works-step"
            />
            <div className="w-112">
                <div className="text-basic mb-1">
                    {title}
                </div>
                <div className="text-small-alt">
                    {content}
                </div>
            </div>
        </div>
    )
}