import * as Yup from 'yup'

import {LOCALE_STRINGS} from "constants/locales";
import {
    DIGITS_ONLY,
    EMAIL_MAX_LENGTH,
    EmailRegExp,
    LENGTH_ERROR,
    NOT_ONLY_SPECIAL_CHARACTERS,
    NOT_START_OR_END_WITH_SPECIAL_CHARACTERS,
    ONLY_DIGITS_DASHES_DOTS_SPACES_COMMAS,
    ONLY_LETTERS_DIGITS_SPACES_DASHES_DECIMAILS,
    ONLY_SPACES_REGEX,
    ONLY_STRICT_LETTERS_DASHES_SPACES_COMMAS,
    PHONE_FIELD_MIN_LENGTH,
    TEXT_FIELD_MAX_LENGTH,
    TEXT_FIELD_MIN_LENGTH,
    YEARS_FIELD_MIN_LENGTH
} from "constants/validations";

export const questionnaireValidationSchema = (language) => {
    const lengthErrorParams = {
        mustBeFrom: LOCALE_STRINGS[language].preOnboardingQuestionnaire_mustBeFrom,
        min: TEXT_FIELD_MIN_LENGTH,
        to: LOCALE_STRINGS[language].preOnboardingQuestionnaire_to,
        max: TEXT_FIELD_MAX_LENGTH,
        characters: LOCALE_STRINGS[language].preOnboardingQuestionnaire_characters
    };

    return Yup.object().shape({
        businessName: Yup.string()
            .test("is-only-spaces",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_cannotContainSpacesOnly,
                (value) => {
                    return !ONLY_SPACES_REGEX.test(value);
                })
            .test(
                "notOnlySpecialCharacters",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_notOnlySpecialCharacters,
                function (value) {
                    return NOT_ONLY_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "shouldNotStartOrEndWithSpecialCharacter",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_shouldNotStartOrEndWithSpecialCharacter,
                function (value) {
                    return NOT_START_OR_END_WITH_SPECIAL_CHARACTERS.test(value)
                })
            .min(TEXT_FIELD_MIN_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_businessName,
                })
            )
            .max(TEXT_FIELD_MAX_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_businessName,
                })
            )
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_businessName} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        yearsInBusiness: Yup.string()
            .test("is-only-spaces",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_cannotContainSpacesOnly,
                (value) => {
                    return !ONLY_SPACES_REGEX.test(value);
                })
            .test(
                "notOnlySpecialCharacters",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_notOnlySpecialCharacters,
                function (value) {
                    return NOT_ONLY_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "shouldNotStartOrEndWithSpecialCharacter",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_shouldNotStartOrEndWithSpecialCharacter,
                function (value) {
                    return NOT_START_OR_END_WITH_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "onlyLetterDigitSpaceDashAllowed",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_onlyLetterDigitSpaceDashAllowed,
                function (value) {
                    return ONLY_LETTERS_DIGITS_SPACES_DASHES_DECIMAILS.test(value)
                })
            .min(YEARS_FIELD_MIN_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_yearsInBusiness,
                    min: YEARS_FIELD_MIN_LENGTH,
                })
            )
            .max(TEXT_FIELD_MAX_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_yearsInBusiness,
                    min: YEARS_FIELD_MIN_LENGTH,
                })
            )
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_yearsInBusiness} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        annualSales: Yup.string()
            .test("is-only-spaces",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_cannotContainSpacesOnly,
                (value) => {
                    return !ONLY_SPACES_REGEX.test(value);
                })
            .test(
                "notOnlySpecialCharacters",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_notOnlySpecialCharacters,
                function (value) {
                    return NOT_ONLY_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "shouldNotStartOrEndWithSpecialCharacter",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_shouldNotStartOrEndWithSpecialCharacter,
                function (value) {
                    return NOT_START_OR_END_WITH_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "onlyDigitDashCommaDotSpaceAllowed",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_onlyDigitsDashesCommasDotsSpacesAllowed,
                function (value) {
                    return ONLY_DIGITS_DASHES_DOTS_SPACES_COMMAS.test(value)
                })
            .min(TEXT_FIELD_MIN_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_annualSales,
                })
            )
            .max(TEXT_FIELD_MAX_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_annualSales,
                })
            )
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_annualSales} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        tradeCommodity: Yup.string()
            .test("is-only-spaces",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_cannotContainSpacesOnly,
                (value) => {
                    return !ONLY_SPACES_REGEX.test(value);
                })
            .test(
                "notOnlySpecialCharacters",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_notOnlySpecialCharacters,
                function (value) {
                    return NOT_ONLY_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "shouldNotStartOrEndWithSpecialCharacter",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_shouldNotStartOrEndWithSpecialCharacter,
                function (value) {
                    return NOT_START_OR_END_WITH_SPECIAL_CHARACTERS.test(value)
                })
            .test(
                "noDigits",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_onlyLettersDashesCommasSpacesAllowed,
                function (value) {
                    return !DIGITS_ONLY.test(value);
                }
            )
            .test(
                "onlyLettersDashesSpacesCommasAllowed",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_onlyLettersDashesCommasSpacesAllowed,
                function (value) {
                    if ((value ?? '').includes('»') || (value ?? '').includes('«')) {
                        return false;
                    }
                    return ONLY_STRICT_LETTERS_DASHES_SPACES_COMMAS.test(value)
                })
            .min(TEXT_FIELD_MIN_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_tradeCommodity,
                })
            )
            .max(TEXT_FIELD_MAX_LENGTH,
                LENGTH_ERROR({
                    ...lengthErrorParams,
                    field: LOCALE_STRINGS[language].preOnboardingQuestionnaire_tradeCommodity,
                }))
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_tradeCommodity} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        email: Yup.string()
            .test(
                "invalidEmail",
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_invalidEmail,
                function (value) {
                    return EmailRegExp.test(value)
                })
            .max(EMAIL_MAX_LENGTH,
                `${LOCALE_STRINGS[language].preOnboardingQuestionnaire_email} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_mustBeNotLongerThan129Chars}`
            )
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_email} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        phone: Yup.string()
            .min(PHONE_FIELD_MIN_LENGTH,
                LOCALE_STRINGS[language].preOnboardingQuestionnaire_phoneMinLengthError
            )
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_phoneNumber} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
        country: Yup.string()
            .required(`${LOCALE_STRINGS[language].preOnboardingQuestionnaire_country} ${LOCALE_STRINGS[language].preOnboardingQuestionnaire_isRequired}`),
    })
}
