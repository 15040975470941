import { TitleWithSubtitle } from "components/TitleWithSubtitle"
import { LOCALE_STRINGS } from "constants/locales"
import { ProductCard } from "./ProductCard"
import { LearnMore } from "components/LearnMore"
import cardImage1 from 'assets/icons/ProductCard1.svg'
import cardImage2 from 'assets/icons/ProductCard2.svg'
import { ROUTES } from "constants/navigation"

export const ProductsSection = ({
    language = 'EN'
}) => {
    return (
        <section className="limit-section pt-20 flex-col items-center">
            <TitleWithSubtitle
                title={LOCALE_STRINGS[language].home_products_title}
                subtitle={LOCALE_STRINGS[language].home_products_subtitle}
                className="mb-12 max-w-150 text-center"
            />
            <div className="w-full mb-8 flex gap-5 two-cards">
                <ProductCard
                    img={cardImage1}
                    title={LOCALE_STRINGS[language].home_products_card1_title}
                    content={LOCALE_STRINGS[language].home_products_card1_content}
                    className=""
                    imgClassName="w-24 h-24"
                />
                <ProductCard
                    img={cardImage2}
                    title={LOCALE_STRINGS[language].home_products_card2_title}
                    content={LOCALE_STRINGS[language].home_products_card2_content}
                    className=""
                    imgClassName="w-24 h-24"
                />
            </div>
            <LearnMore
                link={`${ROUTES.products.path}#page`}
                language={language}
            />
        </section>
    )
}