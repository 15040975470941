import { Header } from "components/Header"
import { LanguageSelect } from "components/LanguageSelect"
import { MobileMenu } from "components/MobileMenu"
import { LOCALE_STRINGS } from "constants/locales"
import { HEADER_NAVIGATION } from "constants/navigation"
import { LocalizationContext } from "contexts/localizationContext"
import { getLinkToPortal } from "helpers/getLinkToPortal"
import { joinClassNames } from "helpers/joinClassNames"
import { useContext } from "react"
import { Link } from "react-router-dom"

export const HeaderWithNav = () => {
    const { language } = useContext(LocalizationContext);
    const linkToPortal = getLinkToPortal(window.location.hostname);
    const path = window.location.pathname;

    const scrollPage = () => {
        const element = document.querySelector('#page');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }  
    }

    return (
        <Header>
            <div className="header__nav">
                <nav>
                    <ul className="home-page__header__nav__list">
                        {HEADER_NAVIGATION.map((route) => {
                            const isCurrentPath = route.path === window.location.pathname;
                            
                            return (
                                <li key={route.path} className="home-page__header__nav__list-item">
                                    {isCurrentPath ? (
                                        <button
                                            className={joinClassNames(
                                                "home-page__header__nav__list-item__link head-button",
                                                path === route.path && "active-path"
                                            )}
                                            onClick={scrollPage}
                                        >
                                                {LOCALE_STRINGS[language][route.title]}
                                        </button>
                                    ) : (
                                        <Link
                                            className={joinClassNames(
                                                "home-page__header__nav__list-item__link",
                                                path === route.path && "active-path"
                                            )}
                                            rel="noopener noreferrer"
                                            to={`${route.path}#page`}>{LOCALE_STRINGS[language][route.title]}
                                        </Link>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </div>
            <div className="header__actions">
                <div className="header__actions__language"><LanguageSelect /></div>
                <Link
                    className="header__actions__login"
                    to={linkToPortal}>{LOCALE_STRINGS[language].login}
                </Link>
                <MobileMenu />
            </div>
        </Header>
    )
}