export const FoldCard = ({
    image,
    title = '',
    subtitle = ''
}) => {
    return (
        <div className="fold-card">
            <img
                alt=""
                src={image}
                className="fold-image"
            />
            <p className="high-title mb-0-5 ">
                {title}
            </p>
            <p className="text-fold color-dark">
                {subtitle}
            </p>
        </div>
    )
}