import {ToastService} from "components/ToastElement";
import {LOCALE_STRINGS} from "constants/locales";
import {REQUEST_URL} from "constants/api";

const languageOptions = {
    FR: 'French',
    EN: 'English',
}

export const submitForm = (values, setSubmitting, language, closeModal, onDecline) => {
    const body = JSON.stringify({
        businessName: values.businessName.trim(),
        yearsInBusiness: values.yearsInBusiness.trim(),
        annualSales: values.annualSales.trim(),
        tradeCommodity: values.tradeCommodity.trim(),
        phoneNumber: `+${values.phone}`,
        country: values.country,
        language: languageOptions[language],
        email: values.email.trim(),
    });

    return fetch(REQUEST_URL, {
        method: 'POST',
        body,
        headers: {
            "Accept": "application/json",
            'Content-type': 'application/json;charset=utf-8',
            'Accept-Language': `${language}`.toLowerCase()
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        })
        .then(() => {
            closeModal();
            ToastService.success(LOCALE_STRINGS[language].preOnboardingQuestionnaire_informationSentSuccessMessage);
        })
        .catch((response) => {
            response.json().then((json) => {
                if ((json.errors[0].key === 'status')) {
                    onDecline();
                    return;
                };
                json.errors?.forEach(err => ToastService.error(err.message))
            })

        })
        .finally(() => setSubmitting(false));
}
