import {ICONS} from "constants/icons";
import logoTitle from "assets/images/logo-title.png";
import {Icon} from "components/Icon";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/navigation";


export const Header = ({ children }) => {
    const isRoot = 
        window.location.pathname === ROUTES.root.path ||
        window.location.pathname === ROUTES.privacyPolicy.path || 
        window.location.pathname === ROUTES.termsOfUse.path;

    const scrollPage = () => {
        const element = document.querySelector('#page');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }  
    }

    return (
        <header className="header">
            <div className="header__content">
                {isRoot ? (
                    <button
                        onClick={scrollPage}
                        className="header__logo"
                    >
                        <Icon icon={ICONS.logo} className="header__logo__icon"/>
                        <img src={logoTitle} alt="Vantage 360 logo" className="header__logo__title"></img>
                    </button>
                ) : (
                    <Link 
                        to={`${ROUTES.root.path}#page`} 
                        className="header__logo"
                        rel="noopener noreferrer"
                    >
                        <Icon icon={ICONS.logo} className="header__logo__icon"/>
                        <img src={logoTitle} alt="Vantage 360 logo" className="header__logo__title"></img>
                    </Link>
                )}
                {children}
            </div>
        </header>
    )
}
