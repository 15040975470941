import { Icon } from "components/Icon"
import { ICONS } from "constants/icons"
import { LOCALE_STRINGS } from "constants/locales"
import { GetStartedButtonWithModal } from "pages/Home/components/GetStartedButtonWithModal"
import logoTitleWhiteOnBlue from "assets/images/logo-title-white-on-blue.png";

export const GetStartedSection = ({
    language = 'EN'
}) => {
    return (
        <section className="home-page__getStarted">
            <div className="home-page__getStarted__content">
                <div className="home-page__getStarted__logo">
                    <Icon icon={ICONS.logo} className="home-page__getStarted__logo__icon"/>
                    <img src={logoTitleWhiteOnBlue} alt="Vantage 360 logo" className="home-page__getStarted__logo__title"></img>
                </div>
                <p className="home-page__getStarted__text">{LOCALE_STRINGS[language].homePage_getStarted_title}</p>
                <GetStartedButtonWithModal />
            </div>
            <div className="get-started" />
        </section>
    )
}