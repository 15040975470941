import { useEffect } from "react";

export const useScrollToElement = () => {
    useEffect(() => {
        const hash = window.location.hash;

        if (hash) {
            const element = document.querySelector(hash);
            
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }   
        }
    }, []);
}