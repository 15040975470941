export const ContactLink = ({
    label,
    link,
    icon
}) => {
    return (
        <div className="footer-contact">
            <img
                alt=""
                src={icon}
            />
            <a className="footer__email__link" href={link}>
                {label}
            </a>
        </div>
    )
}