import { Footer } from "components/Footer";
import { HeaderWithNav } from "components/HeaderWithNav";
import { HeroSection } from "components/HeroSection";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import heroImage from "assets/images/aboutUs-hero.png";
import { GetStartedSection } from "components/GetStartedSection";
import { FoldSection } from "./components/FoldSection";
import { StandsSection } from "./components/StandsSection";
import { WorldSection } from "./components/WorldSection";
import { useScrollToElement } from "hooks/useScrollToElement";

export const AboutUs = () => {
    const language = useDynamicLanguage();
    useScrollToElement();
    
    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <HeroSection
                    imageSrc={heroImage}
                    language={language}
                    titleKey={'hero_aboutUsTitle'}
                    descriptionKey={'hero_aboutUsDescription'}
                    className="-mt-9"
                />
                <FoldSection
                    language={language}
                />
                <StandsSection
                    language={language}
                />
                <WorldSection
                    language={language}
                />
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}