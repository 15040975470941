import { TitleWithSubtitle } from "components/TitleWithSubtitle";
import { LOCALE_STRINGS } from "constants/locales";
import { ProductCard } from "./ProductCard";
import cardImage1 from "assets/images/SolutionCard1.png";
import cardImage2 from "assets/images/SolutionCard2.png";
import { LearnMore } from "components/LearnMore";
import { ROUTES } from "constants/navigation";

export const SolutionsSection = ({
    language = 'EN'
}) => {
    return (
        <section className="limit-section pt-20 flex-col items-center mb-20">
            <TitleWithSubtitle
                title={LOCALE_STRINGS[language].home_solutions_title}
                subtitle={LOCALE_STRINGS[language].home_solutions_subtitle}
                className="mb-12 max-w-150 text-center"
            />
            <div className="w-full mb-8 flex gap-5 two-cards">
                <ProductCard
                    img={cardImage1}
                    title={LOCALE_STRINGS[language].home_solutions_card1_title}
                    content={LOCALE_STRINGS[language].home_solutions_card1_content}
                    className=""
                    imgClassName="solution-image"
                />
                <ProductCard
                    img={cardImage2}
                    title={LOCALE_STRINGS[language].home_solutions_card2_title}
                    content={LOCALE_STRINGS[language].home_solutions_card2_content}
                    className=""
                    imgClassName="solution-image"
                />
            </div>
            <LearnMore
                link={`${ROUTES.solutions.path}#page`}
                language={language}
            />
        </section>
    );
};
