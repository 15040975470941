import { LOCALE_STRINGS } from "constants/locales"
import { useScrollToElement } from "hooks/useScrollToElement";

export const RestrictedSection = ({
    language = 'EN'
}) => {
    useScrollToElement();

    return (
        <section className="px-10 py-20 flex-center w-full">
            <div className="message-section">
                <p className="title text-black text-center">
                    {LOCALE_STRINGS[language].tmbl_restriction_title}
                </p>
                <div className="restricted-content">
                    <p className="text-small">
                        {LOCALE_STRINGS[language].tbml_restriction_content_before}
                    </p>
                    <ol className="restricted-list">
                        <li>
                            {LOCALE_STRINGS[language].tmbl_restriction_list_1}
                        </li>
                        <li>
                            {LOCALE_STRINGS[language].tmbl_restriction_list_2}
                        </li>
                        <li>
                            {LOCALE_STRINGS[language].tmbl_restriction_list_3}
                        </li>
                    </ol>
                    <p className="text-small">
                        {LOCALE_STRINGS[language].tbml_restriction_content_after}
                    </p>
                </div>
            </div>
        </section>
    )
}