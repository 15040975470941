import { LOCALE_STRINGS } from "constants/locales"
import { Link } from "react-router-dom"

export const LearnMore = ({
    link,
    language = 'EN'
}) => {
    return (
        <Link
            className="learn-more"
            to={link}
        >
            {LOCALE_STRINGS[language].home_learn_more}
        </Link>
    )
}