export const CustomTextarea = ({
    type = "text",
    autoComplete = "off",
    name,
    placeholder = "",
    onChange,
    onBlur,
    value,
    placeholderSuffix = "",
    maxLength
}) => {
    const placeholderString = placeholderSuffix ? `${placeholder} ${placeholderSuffix}` : placeholder;

    return (
        <textarea
            className="customInput"
            name={name}
            placeholder={placeholderString}
            type={type}
            autoComplete={autoComplete}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            maxLength={maxLength}
        />
    );
};
