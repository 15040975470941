const PORTAL_LINK_SCHEMA_PREFIX = {
    dev: "dev",
    stage: "stage"
};

export const getLinkToPortal = (hostname) => {
    if (hostname.includes(PORTAL_LINK_SCHEMA_PREFIX.dev)) {
        return "https://portal.dev.vantage-360.com";
    }

    if (hostname.includes(PORTAL_LINK_SCHEMA_PREFIX.stage)) {
        return "https://portal.stage.vantage-360.com";
    }

    return "https://portal.vantage-360.com";
};
