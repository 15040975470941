import fold1 from 'assets/images/fold1.svg';
import fold2 from 'assets/images/fold2.svg';
import fold3 from 'assets/images/fold3.svg';
import { FoldCard } from './FoldCard';
import { LOCALE_STRINGS } from 'constants/locales';

export const FoldSection = ({
    language = 'EN'
}) => {
    return (
        <section className="fold">
            <FoldCard
                image={fold1}
                title={LOCALE_STRINGS[language].aboutUs_fold1_title}
                subtitle={LOCALE_STRINGS[language].aboutUs_fold1_subtitle}
            />
            <FoldCard
                image={fold2}
                title={LOCALE_STRINGS[language].aboutUs_fold2_title}
                subtitle={LOCALE_STRINGS[language].aboutUs_fold2_subtitle}
            />
            <FoldCard
                image={fold3}
                title={LOCALE_STRINGS[language].aboutUs_fold3_title}
                subtitle={LOCALE_STRINGS[language].aboutUs_fold3_subtitle}
            />
        </section>
    )
}