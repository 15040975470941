import { Footer } from "components/Footer";
import { GetStartedSection } from "components/GetStartedSection";
import { HeaderWithNav } from "components/HeaderWithNav";
import { LOCALE_STRINGS } from "constants/locales";
import { useDynamicLanguage } from "hooks/useDynamicLanguage";
import { ContactForm } from "./components/ContactForm";
import { ContactMap } from "./components/ContactMap";
import { useScrollToElement } from "hooks/useScrollToElement";

export const ContactUs = () => {
    const language = useDynamicLanguage();
    useScrollToElement();

    return (
        <>
            <HeaderWithNav />
            <div id="page" />
            <main className="main-content-wrapper home-page__main">
                <section className="contact-section section mb-20 px-5">
                    <h1 className="text-large mb-20 text-center">
                        {LOCALE_STRINGS[language].contactUs_title}
                    </h1>
                    <div className="contact-items">
                        <ContactForm
                            language={language}
                        />
                        <ContactMap
                            language={language}
                        />
                    </div>
                </section>
                <GetStartedSection
                    language={language}
                />
            </main>
            <Footer />
        </>
    )
}