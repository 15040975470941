import { LOCALE_STRINGS } from "constants/locales"
import commitment1 from 'assets/images/commitment1.png'
import commitment2 from 'assets/images/commitment2.png'
import commitment3 from 'assets/images/commitment3.png'
import commitment4 from 'assets/images/commitment4.png'


export const CommitmentSection = ({
    language = 'EN'
}) => {
    return (
        <section className="commitment-section">
            <p className="text-small max-w-116">
                {LOCALE_STRINGS[language].esg_commitment}
            </p>
            <div className="four-cards">
                <img
                    alt=""
                    src={commitment1}
                    className="w-50"
                />
                <img
                    alt=""
                    src={commitment2}
                    className="w-50"
                />
                <img
                    alt=""
                    src={commitment3}
                    className="w-50"
                />
                <img
                    alt=""
                    src={commitment4}
                    className="w-50"
                />
            </div>
        </section>
    )
}