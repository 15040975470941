import {Icon} from "components/Icon";
import {Spinner} from "components/Spinner";

export const Button = ({
    btnText = "",
    onClick,
    isDisabled = false,
    name,
    className = "",
    type = "button",
    icon,
    isLoading
}) => {
    return (
        <button
            className={`button ${className}`}
            onClick={onClick}
            disabled={isDisabled}
            name={name}
            type={type}
        >
            {isLoading && <Spinner />}
            {btnText}
            {icon && <Icon icon={icon} />}
        </button>
    );
}
