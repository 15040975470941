import { LOCALE_STRINGS } from "constants/locales";
import worldEN from 'assets/images/worldEN.svg';
import worldFR from 'assets/images/worldFR.svg';
import mobileWorldEN from 'assets/images/mobileWorldEN.svg';
import mobileWorldFR from 'assets/images/mobileWorldFR.svg';
import useWindowSize from "hooks/useWindowSize";
import { MOBILE } from "constants/media";

export const WorldSection = ({
    language = 'EN'
}) => {
    const { width } = useWindowSize();
    const isMobile = width <= MOBILE;

    let worldImage =  isMobile ? mobileWorldEN : worldEN;

    if (language === 'FR') {
        worldImage =  isMobile ? mobileWorldFR : worldFR;
    }

    return (
        <section className="world-section">
            <div className="flex-col gap-3 text-center">
                <h2 className='title'>
                    {LOCALE_STRINGS[language].aboutUs_world_title}
                </h2>
                <div className="text-small">
                    {LOCALE_STRINGS[language].aboutUs_world_subtitle}
                </div>
            </div>
            <img
                src={worldImage}
                alt=""
                className="world-img"
            />
        </section>
    )
}