import { LOCALE_STRINGS } from "constants/locales"
import { KeyPrincipleCard } from "pages/TBML/components/KeyPrincipleCard"
import esgImg1 from 'assets/images/esg1.svg'
import esgImg2 from 'assets/images/esg2.svg'
import esgImg3 from 'assets/images/esg3.svg'
import esgImg4 from 'assets/images/esg4.svg'
import esgImg5 from 'assets/images/esg5.svg'
import esgImg6 from 'assets/images/esg6.svg'
import esgImg7 from 'assets/images/esg7.svg'
import esgImg8 from 'assets/images/esg8.svg'
import esgImg9 from 'assets/images/esg9.svg'

export const SustainabilitySection = ({
    language = 'EN'
}) => {
    const SUSTAINABILITIES = [
        {
            img: esgImg1,
            title: LOCALE_STRINGS[language].esg_card1_title,
            content: LOCALE_STRINGS[language].esg_card1_content,
            className: ''
        },
        {
            img: esgImg2,
            title: LOCALE_STRINGS[language].esg_card2_title,
            content: LOCALE_STRINGS[language].esg_card2_content,
            className: ''
        },
        {
            img: esgImg3,
            title: LOCALE_STRINGS[language].esg_card3_title,
            content: (
                <div>
                    <div className="line line-0">
                        {LOCALE_STRINGS[language].esg_card3_content1}
                    </div>
                    <div className="line line-1">
                        {LOCALE_STRINGS[language].esg_card3_content2}
                    </div>
                    <div className="line line-2">
                        {LOCALE_STRINGS[language].esg_card3_content3}
                        <a className="contact-link" href="/tbml#restricted">
                            {LOCALE_STRINGS[language].esg_card3_content4}
                        </a>
                        {LOCALE_STRINGS[language].esg_card3_content5}
                    </div>
                </div>
            ),
            className: '',
            skipSplit: true
        },
        {
            img: esgImg4,
            title: LOCALE_STRINGS[language].esg_card4_title,
            content: LOCALE_STRINGS[language].esg_card4_content,
            className: ''
        },
        {
            img: esgImg5,
            title: LOCALE_STRINGS[language].esg_card5_title,
            content: LOCALE_STRINGS[language].esg_card5_content,
            className: 'median-card'
        },
        {
            img: esgImg6,
            title: LOCALE_STRINGS[language].esg_card6_title,
            content: LOCALE_STRINGS[language].esg_card6_content,
            className: 'median-card'
        },
        {
            img: esgImg7,
            title: LOCALE_STRINGS[language].esg_card7_title,
            content: LOCALE_STRINGS[language].esg_card7_content,
            className: ''
        },
        {
            img: esgImg8,
            title: LOCALE_STRINGS[language].esg_card8_title,
            content: LOCALE_STRINGS[language].esg_card8_content,
            className: ''
        },
        {
            img: esgImg9,
            title: LOCALE_STRINGS[language].esg_card9_title,
            content: LOCALE_STRINGS[language].esg_card9_content,
            className: 'grid-colspan-2 median-card'
        },
    ]

    return (
        <section className="esg-card-section">
            <div className="card principles-card esg-section">
                <p className="text-basic text-center">
                    {LOCALE_STRINGS[language].esg_title}
                </p>
                <div className="principles-section esg">
                    {SUSTAINABILITIES.map(({ img, title, content, className, skipSplit }) => (
                        <KeyPrincipleCard
                            key={title}
                            img={img}
                            title={title}
                            content={content}
                            className={className}
                            skipSplit={skipSplit}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}