import {useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";

import {Header} from "components/Header";
import {LocalizationContext} from "contexts/localizationContext";
import {LOCALE_STRINGS} from "constants/locales";
import {TermsOfUseAndPrivacyPolicyFooter} from "components/Footer/TermsOfUseAndPrivacyPolicyFooter";
import {ROUTES} from "constants/navigation";


export const TermsOfUse = () => {
    const { language: contextLanguage, changeLanguage } = useContext(LocalizationContext);
    const { search } = useLocation();

    const language = contextLanguage ?? "EN";

    useEffect(() => {
        const queryLang = search.split("=")[1];
        if (!queryLang) return;
        changeLanguage(queryLang.toUpperCase());
    }, [changeLanguage, search]);

    return (
        <div className="termsOfUseAndPrivacyPolicy">
            <Header />
            <div id="page" />
            <main className="termsOfUseAndPrivacyPolicy__main">
                <h1 className="termsOfUseAndPrivacyPolicy__title">{LOCALE_STRINGS[language].termsOfUse}</h1>
                <div className="termsOfUseAndPrivacyPolicy__main__content">
                    <section>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.paragraphOnePartOne}
                            <span>{" "}</span>
                            <a className="termsOfUseAndPrivacyPolicy__email__link"
                                href="https://vantage-360.com/">www.vantage-360.com
                            </a>
                            <span>{" "}</span>
                            {LOCALE_STRINGS[language].termsAndConditions.paragraphOnePartTwo}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.paragraphTwo}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.consent}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.consentContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.summary}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.summaryContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.eligibility}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.eligibilityContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.onboarding}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.onboardingContent.paragraphOne}
                            <span>{" "}</span>
                            <a className="termsOfUseAndPrivacyPolicy__email__link"
                               href="mailto:support@vantage-360.com">support@vantage-360.com
                            </a>
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.intellectualPropertyRights}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.intellectualPropertyRightsContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.financialInformation}
                        </h6>
                        <ol className="termsOfUseAndPrivacyPolicy__section-list">
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.financialInformationContent.paragraphOne}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.financialInformationContent.paragraphTwo}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.financialInformationContent.paragraphThree}
                            </li>
                        </ol>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.emailCommunication}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.emailCommunicationContent.paragraphOne}
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.forbiddenBehavior}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.forbiddenBehaviorContent.paragraphOne}
                        </p>
                        <ol className="termsOfUseAndPrivacyPolicy__section-list">
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.forbiddenBehaviorContent.paragraphTwo}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.forbiddenBehaviorContent.paragraphThree}
                            </li>
                            <li className="termsOfUseAndPrivacyPolicy__section-list__item">
                                {LOCALE_STRINGS[language].termsAndConditions.forbiddenBehaviorContent.paragraphFour}
                            </li>
                        </ol>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.changesToContractualTerms}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.changesToContractualTermsContent.paragraphOne}
                        </p>
                    </section>

                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.termination}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.terminationContent.paragraphOne}
                            <span>{" "}</span>
                            <a className="termsOfUseAndPrivacyPolicy__email__link"
                               href="mailto:support@vantage-360.com">support@vantage-360.com
                            </a>
                        </p>
                    </section>
                    <section>
                        <h6 className="termsOfUseAndPrivacyPolicy__section-heading">
                            {LOCALE_STRINGS[language].termsAndConditions.indemnity}
                        </h6>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            {LOCALE_STRINGS[language].termsAndConditions.indemnityContent.paragraphOne}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            <span className="termsOfUseAndPrivacyPolicy__section-paragraph__heading">
                                {LOCALE_STRINGS[language].termsAndConditions.governingLaw}.
                            </span>
                            <span>{" "}</span>
                            {LOCALE_STRINGS[language].termsAndConditions.governingLawContent.paragraphOne}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            <span className="termsOfUseAndPrivacyPolicy__section-paragraph__heading">
                                {LOCALE_STRINGS[language].termsAndConditions.privacyPolicy}.
                            </span>
                            <span>{" "}</span>
                            {LOCALE_STRINGS[language].termsAndConditions.privacyPolicyContent.paragraphOne}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            <span className="termsOfUseAndPrivacyPolicy__section-paragraph__heading">
                                {LOCALE_STRINGS[language].termsAndConditions.forceMajeure}.
                            </span>
                            <span>{" "}</span>
                            {LOCALE_STRINGS[language].termsAndConditions.forceMajeureContent.paragraphOne}
                        </p>
                        <p className="termsOfUseAndPrivacyPolicy__section-paragraph">
                            <span className="termsOfUseAndPrivacyPolicy__section-paragraph__heading">
                                {LOCALE_STRINGS[language].termsAndConditions.contactInformation}.
                            </span>
                            <span>{" "}</span>
                            {LOCALE_STRINGS[language].termsAndConditions.contactInformationContent.paragraphOne}
                            <span>{" "}</span>
                            <a className="termsOfUseAndPrivacyPolicy__email__link"
                               href="mailto:info@vantage-360.com">info@vantage-360.com.
                            </a>
                        </p>
                    </section>
                </div>
            </main>
            <TermsOfUseAndPrivacyPolicyFooter>
                <Link
                    to={ROUTES.privacyPolicy.path} className="termsOfUseAndPrivacyPolicy__footer__links__item"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {LOCALE_STRINGS[language][ROUTES.privacyPolicy.name]}
                </Link>
            </TermsOfUseAndPrivacyPolicyFooter>
        </div>
    );
};
