import {useState} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import {ROUTES} from "constants/navigation";
import {LocalizationContext} from "contexts/localizationContext";
import {KEY_LANGUAGE, LOCALES} from "constants/locales";
import {ToastElement} from "components/ToastElement";

import './App.css';

function App() {
    const [language, setLanguage] = useState(localStorage.getItem(KEY_LANGUAGE) || LOCALES.EN);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem(KEY_LANGUAGE, newLanguage);
    };

    return (
        <LocalizationContext.Provider value={{ language, changeLanguage }}>
            <Router>
                <Routes>
                    {Object.values(ROUTES).map((route) => (
                        <Route
                            key={route.path}
                            path={route.path} 
                            element={route.element ?? <div />}
                        />
                    ))}
                </Routes>
            </Router>
            <ToastElement/>
        </LocalizationContext.Provider>
    );
}

export default App;
