import image1 from 'assets/images/Expirience1.svg';
import image2 from 'assets/images/Expirience2.svg';
import image3 from 'assets/images/Expirience3.svg';
import image4 from 'assets/images/Expirience4.svg';
import { LOCALE_STRINGS } from 'constants/locales';
import { ExperienceCard } from './ExperienceCard';


export const ExperienceSection = ({
    language = 'EN'
}) => {
    return (
        <section className="px-10 w-full expirience-section">
            <h2 className='title'>
                {LOCALE_STRINGS[language].experience_title}
            </h2>
            <div className='flex-center experience-cards'>
                <ExperienceCard
                    image={image1}
                    content={LOCALE_STRINGS[language].experience_card_1}
                />
                <ExperienceCard
                    image={image2}
                    content={LOCALE_STRINGS[language].experience_card_2}
                />
                <ExperienceCard
                    image={image3}
                    content={LOCALE_STRINGS[language].experience_card_3}
                />
                <ExperienceCard
                    image={image4}
                    content={LOCALE_STRINGS[language].experience_card_4}
                />
            </div>
        </section>
    )
}