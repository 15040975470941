import { LOCALE_STRINGS } from "constants/locales"
import principleImage1 from 'assets/images/Principle1.svg';
import principleImage2 from 'assets/images/Principle2.svg';
import principleImage3 from 'assets/images/Principle3.svg';
import principleImage4 from 'assets/images/Principle4.svg';
import principleImage5 from 'assets/images/Principle5.svg';
import { KeyPrincipleCard } from "./KeyPrincipleCard";

const PRINCIPLES = [
    {
        img: principleImage1,
        title: 'tbml_principle_title_1',
        content: 'tbml_principle_content_1',
        className: ''
    },
    {
        img: principleImage2,
        title: 'tbml_principle_title_2',
        content: 'tbml_principle_content_2',
        className: ''
    },
    {
        img: principleImage3,
        title: 'tbml_principle_title_3',
        content: 'tbml_principle_content_3',
        className: ''
    },
    {
        img: principleImage4,
        title: 'tbml_principle_title_4',
        content: 'tbml_principle_content_4',
        className: ''
    },
    {
        img: principleImage5,
        title: 'tbml_principle_title_5',
        content: 'tbml_principle_content_5',
        className: 'grid-colspan-2',
        id: "restricted"
    },
]

export const KeyPrinciplesSection = ({
    language = 'EN'
}) => {
    return (
        <section className="principles-section">
            <div className="card principles-card">
                <p className="text-question text-center">
                    {LOCALE_STRINGS[language].tbml_keys_title}
                </p>
                <div className="principles-section">
                    {PRINCIPLES.map(({ img, title, content, className, id }) => (
                        <KeyPrincipleCard
                            key={title}
                            img={img}
                            title={LOCALE_STRINGS[language][title]}
                            content={LOCALE_STRINGS[language][content]}
                            className={className}
                            imgClassName="mb-3"
                            id={id}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}